<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <div class="columns is-gapless">
              <div class="column">
                <p class="title">Sublet</p>
                <subtitle-totals :labels="['Sublet Total', 'Grand Total']"
                  :totals="[subletTotal, innerValue.totalExGst]"
                  :shows="[true, true]" />
              </div>
              <div class="column">
                <div class="control is-pulled-right">
                  <div v-if="!audanetLock"
                    class="buttons">
                    <button v-if="innerValue.subQuoteNo > 0 && !innerValue.readOnly && !readOnlyView"
                      class="button is-success tooltip is-tooltip-topright"
                      data-tooltip="Adjust Main Quote Items"
                      :disabled="!isDeleteMainQuoteItemsEnabled"
                      @click="toggleAvailableItemsMain()">
                      <span class="icon">
                        <i class="mdi"
                          :class="{ 'mdi-adjust' : !isAvailableItemsMainActive, 'mdi-close' : isAvailableItemsMainActive }" />
                      </span>
                      <span>{{ isAvailableItemsMainActive ? 'Done' : 'Adjust' }}</span>
                    </button>
                    <button v-if="!value.isAssessmentEnabled"
                      class="button is-primary tooltip is-tooltip-topright"
                      data-tooltip="Toggle Deleted Parts Visibility"
                      @click="()=>{ displayDeleted = !displayDeleted }">
                      <span class="icon">
                        <i class="mdi"
                          :class="{ 'mdi-eye-off' : displayDeleted, 'mdi-eye' : !displayDeleted }" />
                      </span>
                      <span>{{ displayDeleted ? 'Hide' : 'Show' }}</span>
                    </button>
                    <button v-if="!innerValue.readOnly && !readOnlyView"
                      class="button is-primary tooltip is-tooltip-topright"
                      data-tooltip="Add new sublet"
                      @click="toggleAvailableItems()">
                      <span class="icon">
                        <i class="mdi"
                          :class="{ 'mdi-plus' : !isAvailableItemsActive, 'mdi-minus' : isAvailableItemsActive }" />
                      </span>
                      <span>{{ isAvailableItemsActive ? 'Close' : 'Add' }}</span>
                    </button>
                    <button v-if="!innerValue.readOnly && !readOnlyView"
                      class="button is-primary tooltip is-tooltip-topright"
                      data-tooltip="Add blank item"
                      @click="addBlankItem">
                      <span class="icon">
                        <i class="mdi mdi-shape-square-plus" />
                      </span>
                    </button>
                  </div>
                  <div v-if="audanetLock && !$showSpinnerRequestCount">
                    <audanet-lock-notification />
                  </div>
                </div>
              </div>
            </div>
            <available-sublets v-if="isAvailableItemsActive"
              v-model="innerValue.sublets"
              :quote-id="value.quoteId"
              :labour-type="value.labourType"
              :next-line-number="nextLineNumber"
              :is-audanet="value.audatex !== null"
              @item-added="onItemAdded" />
            <available-sublets-main v-if="isAvailableItemsMainActive"
              v-model="innerValue.sublets"
              :quote-id="value.quoteId"
              :quote-no="value.quoteNo"
              :sub-quote-no="value.subQuoteNo"
              :labour-type="value.labourType"
              :next-line-number="nextLineNumber"
              @item-added="onItemAdded" />
            <div v-if="isAvailableItemsActive || isAvailableItemsMainActive"
              class="is-divider" />
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              :draggable="true"
              :show-pagination="false"
              @pageChanged="onPageChange"
              @sort="sort">
              <draggable v-model="innerValue.sublets"
                :tag="'tbody'"
                v-bind="{'disabled': !isRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
                @change="itemMoved"
                @end="endMove"
                handle=".item-drag-handle">
                <tr v-for="(item, index) in activeItems"
                  :key="item.quoteItemId"
                  :class="[defaultRowColourClass(item, getItemAssessments(item), displayDeleted), rowDisplayStatus(item, getItemAssessments(item))]"
                  @click="highlightSelected(index, $event)"
                  :data-itemid="item.quoteItemId">
                  <td v-show="$userInfo.isSupportUser"
                    class="has-vertical-middle has-text-right linenumber-input">
                    <vue-numeric v-if="!innerValue.readOnly && $userInfo.isSupportUser && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="defaultRowColourClass(item, getItemAssessments(item))"
                      v-model.number="item.lineNumber"
                      :precision="0"
                      separator="" />
                    <span v-else>{{ item.lineNumber }}</span>
                  </td>
                  <td class="has-vertical-middle itemno-input">
                    <input v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="input"
                      :class="[defaultRowColourClass(item, getItemAssessments(item)), !isValidItemNo(item.quoteItemId) ? 'is-danger': '']"
                      placeholder="Item No."
                      v-model="item.itemNo">
                    <span v-else>{{ item.itemNo }}</span>
                  </td>
                  <td class="has-vertical-middle itemdesc-input">
                    <item-description-autocomplete v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && $company.setting.itemAutoCompleteEnabled && !isItemRemoved(item, getItemAssessments(item))"
                      v-model="item.itemDesc"
                      schedule-id=""
                      body-id=""
                      :class-prop="(!isValidItemDesc(item.quoteItemId) ? 'is-danger ': '') + defaultRowColourClass(item, getItemAssessments(item))"
                      :item-type="itemTypes.SUBL"
                      :times-style="itemTypes.SUBL" />
                    <input v-else-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !$company.setting.itemAutoCompleteEnabled && !isItemRemoved(item, getItemAssessments(item))"
                      class="input"
                      :class="[defaultRowColourClass(item, getItemAssessments(item)), !isValidItemDesc(item.quoteItemId) ? 'is-danger': '']"
                      placeholder="Item Description"
                      v-model="item.itemDesc">
                    <span v-else>{{ item.itemDesc }}</span>
                    <div v-if="item.itemComment"
                      class="help is-primary">
                      <span class="has-text-weight-bold">Comment: </span>
                      <span>{{ item.itemComment }}</span>
                    </div>
                  </td>
                  <td class="has-vertical-middle is-content-width">
                    <div v-if="sublets && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="select">
                      <select v-model="item.subletTypeId"
                        :class="defaultRowColourClass(item, getItemAssessments(item))">
                        <option v-for="sublet in sublets"
                          :value="sublet.subletTypeId"
                          :key="sublet.subletTypeId">{{ sublet.script }}</option>
                      </select>
                    </div>
                    <span v-else>{{ item.subletType }}</span>
                  </td>
                  <!-- <td class="">{{item.vendorName}}</td> -->
                  <td class="has-vertical-middle vendor-input">
                    <multiselect v-if="vendors[index] && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      :class="defaultRowColourClass(item, getItemAssessments(item))"
                      deselect-label="Can't remove this value"
                      v-model="selectedVendors[index]"
                      track-by="id"
                      label="name"
                      placeholder=""
                      :options="vendors[index]"
                      :loading="isVendorLoading"
                      :internal-search="false"
                      :options-limit="vendorFilter.pageSize"
                      @search-change="searchVendor($event, index)"
                      @select="selectVendor($event, index)"
                      :searchable="true"
                      :show-labels="false"
                      :allow-empty="true">
                      <template slot="option"
                        slot-scope="props">
                        <span>{{ props.option.name }}</span>
                      </template>
                      <span class="has-text-danger"
                        slot="noResult">Vendor not found.</span>
                    </multiselect>
                    <span v-else>{{ item.vendorName }}</span>
                  </td>
                  <td class="has-vertical-middle has-text-centered">
                    <div v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                      <input v-model="item.reportOnly"
                        :disabled="!canMakeReportOnly(item)"
                        type="checkbox">
                      <div class="state p-info">
                        <i class="icon mdi mdi-check" />
                        <label />
                      </div>
                    </div>
                    <span v-else
                      class="icon has-text-info">
                      <i class="mdi mdi-24px"
                        :class="[ item.reportOnly ? 'mdi-check' : '' ]" />
                    </span>
                  </td>
                  <td class="has-vertical-middle has-text-centered is-content-width">{{ item.quantityOrdered }}</td>
                  <td class="has-vertical-middle has-text-centered is-content-width">{{ item.quantityReceived }}</td>
                  <td class="has-vertical-middle has-text-right money-input">
                    <vue-numeric v-if="!innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="defaultRowColourClass(item, getItemAssessments(item))"
                      v-model.number="item.value"
                      :minus="innerValue.subQuoteNo > 0"
                      :precision="2"
                      @input="updateValue(index,item)" />
                    <span v-else>{{ item.value | formatNumber($userInfo.locale) }}</span>
                  </td>
                  <td class="has-vertical-middle has-text-right"
                    :class="{'money-input': !item.deleteOtherItem}">
                    <vue-numeric v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="defaultRowColourClass(item, getItemAssessments(item))"
                      v-model.number="item.buyPrice"
                      :min="0"
                      :disabled="item.deleteOtherItem || (item.quantityReceived>0 && !$userInfo.isSupportUser)"
                      :precision="2" />
                    <span v-else>{{ item.buyPrice | formatNumber($userInfo.locale) }}</span>
                  </td>
                  <td v-show="$userInfo.isSupportUser"
                    class="has-vertical-middle itemstatus-input">
                    <input v-if="!innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input is-capitalized"
                      v-model="item.itemStatus">
                    <span v-else>{{ item.itemStatus }}</span>
                  </td>
                  <td class="has-vertical-middle has-text-centered is-content-width">
                    <a v-if="item.subletAuthorityDocument"
                      class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Click to view"
                      @click="viewPdf(index)">
                      <span class="icon">
                        <i class="mdi mdi-24px"
                          :class="[ item.subletAuthorityDocument !== null ? 'mdi-file-pdf' : '']" />
                      </span>
                    </a>
                  </td>
                  <td class="has-vertical-middle has-text-centered is-content-width">
                    <div class="icon-container items-center">
                      <supplementary-icon v-if="innerValue.quoteAssessments.length > 0"
                        :item="item"
                        :item-assessments="getItemAssessments(item)"
                        :next-assessment-number="nextSupplemetryNo"
                        :quoting-method="innerValue.quotingMethod"
                        :icon-size="4"
                        class="icon" />
                      <button v-if="!audanetLock && !isItemRemoved(item, getItemAssessments(item)) && (item.isDeleted === false || item.deleted === false)"
                        class="button is-danger is-small is-inverted"
                        @click="deleteItem(item, index)"
                        :disabled="innerValue.readOnly || readOnlyView || isItemRemoved(item, getItemAssessments(item))">
                        <span class="icon is-medium">
                          <i class="mdi mdi-delete mdi-24px" />
                        </span>
                      </button>
                      <button class="button is-primary is-small is-inverted item-drag-handle pr-0 pl-1"
                        :disabled="innerValue.readOnly || readOnlyView">
                        <span class="icon is-medium">
                          <i class="mdi mdi-drag-vertical mdi-24px" />
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              </draggable>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
              <template slot="footer">
                <tr>
                  <th class="has-text-right has-text-success"
                    :colspan="totalColSpan">Totals</th>
                  <th class="has-text-right has-text-success"
                    :class="[ innerValue.sublTotal >= 0 ? 'has-text-success' : 'has-text-danger' ]">{{ innerValue.sublTotal | formatCurrency($userInfo.locale) }}</th>
                  <th colspan="100%" />
                </tr>
              </template>
            </bulma-table>
          </div>
        </div>
      </article>
    </div>
    <totals-floating-widget :active.sync="isFloatingWidgetActive"
      title="Sublet Totals"
      :totals="subletTotal"
      :total-ex-gst="innerValue.totalExGst" />
    <pdf-modal v-if="isPdfModalActive"
      :title="subletPdfTitle"
      :pdf="pdfDoc"
      :active.sync="isPdfModalActive"
      @close="closePdf()" />
  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import VueNumeric from '@/components/VueNumeric'
import QuoteService from './QuoteService'
import VendorService from '@/views/vendor/VendorService'
import Guid from '@/components/Guid'
import _debounce from 'lodash.debounce'
import Multiselect from 'vue-multiselect'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { SubletColumns } from './columns'
import { PdfModal } from '@/components/BulmaModal'
import { QuoteItemStatusTypes, EventHubTypes, ItemCategoryTypes, ItemTypes } from '@/enums'
// import _orderBy from 'lodash/orderBy'
import { TotalsFloatingWidget, AvailableSublets, AvailableSubletsMain, SupplementaryIcon } from './components'
import { QuoteAssessmentMixin, QuoteItemValidationMixin, QuoteSubletMixin } from './mixins'
import { QuoteItemModel } from '@/classes/viewmodels'
import draggable from 'vuedraggable'
import SubtitleTotals from '@/components/SubtitleTotals'
import { ItemDescriptionAutocomplete } from './components/ItemDescriptionAutocomplete'

export default {
  name: 'QuoteSublet',
  components: {
    BulmaTable,
    PdfModal,
    VueNumeric,
    Multiselect,
    TotalsFloatingWidget,
    AvailableSublets,
    AvailableSubletsMain,
    SupplementaryIcon,
    SubtitleTotals,
    draggable,
    ItemDescriptionAutocomplete
  },
  mixins: [NumberFiltersMixin, QuoteItemValidationMixin, QuoteSubletMixin, QuoteAssessmentMixin],
  props: {
    value: null,
    nextLineNumber: {
      type: Number,
      default: 0
    },
    readOnlyView: {
      type: Boolean,
      default: false
    },
    audanetLock: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      displayDeleted: false,
      innerValue: null,
      selectedRow: null,
      // totalRows: 0,
      isTableLoading: false,
      filter: {
        sortColumn: SubletColumns[1].name,
        sortOrder: SubletColumns[1].defaultOrder,
        pageIndex: 1,
        pageSize: 100
      },
      // items: null,
      isPdfModalActive: false,
      pdfDoc: '',
      subletPdfTitle: '',
      sublets: null,
      vendors: [], // Vendors array. Each entry is an array
      vendorFilter: {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      },
      isVendorLoading: false,
      selectedVendors: [], // Selected vendor array. Contains vendor object
      vendorFilters: [], // Filter array. Contains filter (vendorFilter type)
      isFloatingWidgetActive: true,
      isAvailableItemsActive: false,
      isAvailableItemsMainActive: false,
      baseVendors: []
    }
  },
  computed: {
    columns() {
      const c = SubletColumns
      c[1].isVisible = this.$userInfo.isSupportUser
      c[c.length - 3].isVisible = this.$userInfo.isSupportUser
      return SubletColumns
    },
    activeItems() {
      return this.items
    },
    totalRows() {
      return this.activeItems.length
    },
    isDeleteMainQuoteItemsEnabled() {
      return true
    },
    items() {
      if (this.innerValue && this.innerValue.sublets) {
        return this.innerValue.sublets //.filter((i) => !i.deleted)
      }
    },
    totalColSpan() {
      let length = this.columns.length - 7
      if (this.$userInfo.isSupportUser) {
        length++
      }
      return length
    },
    isRowDraggable() {
      return !this.innerValue.readOnly
    },
    itemTypes() {
      return ItemTypes
    },
    itemsAssessments() {
      return this.getSubletItemsAssessments()
    },
    nextSupplemetryNo() {
      const nextSuppNo = Math.max(...this.value.quoteAssessments.map((i) => i.subQuoteNo)) + 1
      return nextSuppNo
    }
  },
  watch: {
    // value: {
    //   handler: function(newVal, oldVal) {
    //     this.innerValue = newVal
    //     this.filter.sortColumn = ''
    //     this.filter.sortOrder = ''
    //     // this.items = _orderBy(this.innerValue.sublets.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
    //   },
    //   deep: true
    // },
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    activeItems(newVal, odlVal) {
      // console.log(this.items)
      if (odlVal) {
        this.getVendors()
      }
    }
  },
  async created() {
    this.innerValue = this.value
    // this.items = _orderBy(this.innerValue.sublets.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
    // this.items = _orderBy(
    //   this.value.items.filter(i => i.itemType === ItemCategoryTypes.SUBL && !i.deleted),
    //   [this.filter.sortColumn],
    //   [this.filter.sortOrder]
    // )
    // this.totalRows = this.items.length
    this.getSublets()
    // this.generateSelectedVendors()
    await this.getBaseVendors()
    this.getVendors()
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = this.value
    })
    this.$v.entity.sublets.$touch()
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  methods: {
    highlightSelected(index, event) {
      // this.selectedRow = index
    },
    onPageChange(pageIndex) {},
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      // this.items = _orderBy(this.innerValue.sublets.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
      // this.getVendors()
    },
    viewPdf(index, event) {
      this.pdfDoc = this.items[index].subletAuthorityDocument
      this.subletPdfTitle = `Sublet authority document for Item No. ${this.items[index].itemNo}`
      // console.log(index, this.pdfDoc)
      this.isPdfModalActive = true
    },
    closePdf() {
      this.isPdfModalActive = false
    },
    async getSublets() {
      this.sublets = await QuoteService.getSubletsProto(!this.value.audatex)
    },
    initArrays(length) {
      this.vendors = new Array(length)
      this.vendorFilters = new Array(length)
      this.selectedVendors = new Array(length)
      let size = length
      while (size--) {
        this.vendors.splice(size, 1, null)
        this.vendorFilters.splice(size, 1, null)
        this.selectedVendors.splice(size, 1, null)
      }
    },
    async getVendorNameById(vendorId) {
      const filter = {
        id: vendorId,
        name: '',
        pageIndex: 1,
        pageSize: 50
      }
      const vendor = await VendorService.getVendorDropdownProto2(filter)
      console.log(vendor)
      return vendor[0].name
    },
    async getBaseVendors() {
      const filter = {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      }
      this.baseVendors = await VendorService.getVendorDropdownProto2(filter)
    },
    async getVendor(index, filter) {
      let itemVendors
      if (filter.vendorId !== Guid.empty()) {
        itemVendors = await VendorService.getVendorDropdownProto2(filter)
      } else {
        itemVendors = this.baseVendors
      }
      this.vendors.splice(index, 1, itemVendors)
      this.vendorFilters.splice(index, 1, filter)
      this.selectedVendors.splice(index, 1, filter.id !== Guid.empty() ? itemVendors[0] : null)
    },
    async getVendors() {
      this.isVendorLoading = true
      const vm = this
      // this.items.forEach(async function(item, index) {
      //   const vendorFilter = {
      //     id: item.vendorId,
      //     name: '',
      //     pageIndex: 1,
      //     pageSize: 50
      //   }
      //   const itemVendors = await VendorService.getVendorDropdownProto2(vendorFilter)
      //   vm.vendors.splice(index, 1, itemVendors)
      //   vm.vendorFilters.splice(index, 1, vendorFilter)
      //   // vm.vendorFilters.push(vendorFilter)
      //   // vm.vendors.push(itemVendors)
      // })
      this.initArrays(this.activeItems.length)
      const promises = this.activeItems.map(async function (item, index) {
        const vendorId = item.vendorId || Guid.empty()
        const vendorFilter = {
          id: vendorId,
          name: '',
          pageIndex: 1,
          pageSize: 50
        }
        vm.getVendor(index, vendorFilter)
      })
      await Promise.all(promises)
      this.isVendorLoading = false
    },
    searchVendor: _debounce(async function (query, index) {
      this.isVendorLoading = true
      this.vendorFilters[index].name = query
      // console.log(query, index)
      this.vendors[index] = await VendorService.getVendorDropdownProto2(this.vendorFilters[index])
      this.isVendorLoading = false
    }, 500),
    // generateSelectedVendors() {
    //   const vm = this
    //   vm.selectedVendors = []
    //   this.items.forEach(async function(item) {
    //     const vendorFilter = {
    //       id: item.vendorId,
    //       name: '',
    //       pageIndex: 1,
    //       pageSize: 50
    //     }
    //     const vendors = await VendorService.getVendorDropdownProto2(vendorFilter)
    //     vm.selectedVendors.push(vendors[0])
    //   })
    // },
    async selectVendor(selected, index) {
      // console.log(selected, index)
      const vendorFilter = {
        id: selected.id,
        name: '',
        pageIndex: 1,
        pageSize: 50
      }
      this.vendorFilters.splice(index, 1, vendorFilter)
      this.vendors[index] = await VendorService.getVendorDropdownProto2(vendorFilter)
      // this.items[index].vendorId = selected.id
      // this.items[index].vendorName = selected.name
      const itemToUpdate = this.innerValue.sublets.find((i) => i.quoteItemId === this.activeItems[index].quoteItemId)
      itemToUpdate.vendorId = selected.id
      itemToUpdate.vendorName = selected.name
    },
    updateValue(index, item) {
      if (item && item.deleteOtherItem && item.value > 0) {
        item.value = item.value * (item.itemQuantity !== 0 ? item.itemQuantity : 1)
      }
      this.updateTotal()
    },
    updateTotal(index) {
      this.isFloatingWidgetActive = true
    },
    deleteItem(item, index) {
      let originalItemStatus = item.itemStatus
      if (item.isNew) {
        const itemIndex = this.innerValue.sublets
          .map(function (obj) {
            return obj.quoteItemId
          })
          .indexOf(item.quoteItemId)
        if (itemIndex >= 0) {
          this.innerValue.sublets.splice(itemIndex, 1)
          // this.items.splice(index, 1)
        }
      } else {
        item.deleted = true
        item.isDeleted = true
        item.itemStatus = QuoteItemStatusTypes.Deleted
        item.originalItemStatus = originalItemStatus
      }
      this.updateTotal()
      this.$snackbar.open({
        message: `Item <span class="is-italic">${item.itemDesc}</span> deleted`,
        type: 'is-danger',
        position: 'is-bottom',
        actionText: 'Undo',
        onAction: () => {
          if (item.isNew) {
            this.innerValue.sublets.splice(this.innerValue.sublets.length, 1, item)
            // this.items.splice(this.items.length, 1, item)
          } else {
            item.deleted = false
            item.isDeleted = false
            item.itemStatus = originalItemStatus
          }
          this.updateTotal()
        }
      })
    },
    restoreItem(item, index) {
      item.deleted = false
      item.isDeleted = false
      //TODO: must set item status to original item status
      this.updateTotal()
    },
    toggleAvailableItems() {
      this.isAvailableItemsMainActive = false
      this.isAvailableItemsActive = !this.isAvailableItemsActive
    },
    toggleAvailableItemsMain() {
      this.isAvailableItemsActive = false
      this.isAvailableItemsMainActive = !this.isAvailableItemsMainActive
    },
    onItemAdded(newItem) {
      this.filter.sortColumn = ''
      this.filter.sortOrder = ''
      this.innerValue.lines = newItem.lineNumber + 1
      this.innerValue.sublets.splice(this.innerValue.sublets.length, 1, newItem)
      // this.items = _orderBy(this.innerValue.sublets.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
      this.$toast.open({
        message: 'Item added',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })
      // this.vendors.splice(this.vendors.length, 1, this.baseVendors)
      // this.vendorFilters.splice(this.vendorFilters.length, 1, this.vendorFilter)
    },
    getSubletDescription: function (subletTypeId) {
      if (this.sublets && this.sublets.length > 0) {
        const sublet = this.sublets.find((s) => s.subletTypeId === subletTypeId)
        return sublet ? sublet.script : ''
      }
    },
    addBlankItem() {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, '', '', ItemCategoryTypes.SUBL)
      newItem.lineNumber = this.innerValue.audatex === null ? this.nextLineNumber : 0
      newItem.itemNo = this.$filters.pad(newItem.lineNumber, 4)
      newItem.value = 0
      newItem.sortNo = this.innerValue.sublets.length ? Math.max(...this.innerValue.sublets.map((i) => i.sortNo)) + 1 : 1
      this.innerValue.sublets.splice(this.innerValue.sublets.length, 1, newItem)
      this.innerValue.lines = this.nextLineNumber + 1
      this.$toast.open({
        message: 'Item added',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })
    },
    itemMoved(event) {
      // console.log(event.moved)
      let sortNo = Math.min(...this.innerValue.sublets.map((i) => i.sortNo))
      this.innerValue.sublets.forEach((item) => {
        item.sortNo = sortNo
        sortNo++
      })
    },
    endMove(event) {},
    getSubletItemsAssessments() {
      return this.getItemsAssessments(this.items, this.innerValue.quoteAssessments)
    },
    getItemAssessments(item) {
      const itemAssessments = this.itemsAssessments.find((i) => i.quoteItemId === item.quoteItemId)
      if (itemAssessments) {
        return itemAssessments.assessments
      }
      return []
    },
    canMakeReportOnly(item) {
      const assessments = this.getItemAssessments(item)
      if (assessments.length > 0) {
        const lastAssessment = assessments[assessments.length - 1]
        const lastValues = lastAssessment[lastAssessment.length - 1]
        if (!lastValues.reportOnly) {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.linenumber-input {
  width: 60px;
}
.itemno-input {
  max-width: 2.5em;
}
.itemdesc-input {
  min-width: 20em;
}
.money-input {
  width: 6em;
}
.qty-input {
  max-width: 1.8em;
}
.partno-input {
  max-width: 6.5em;
}
.vendor-input {
  min-width: 15em;
}
.itemstatus-input {
  width: 50px;
}
</style>
